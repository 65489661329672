@import "../foundation/functions";

$global-font-size: 16px;

// Media Query Ranges
$screen: "only screen";

$breakpoints: (
        small: 0,
        medium: 640px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1440px,
);

$breakpoints-down: (
        small: 640px,
        medium: 1024px,
        large: 1200px,
        xlarge: 1440px,
);

$small-screen: 639px;
$medium-screen: 1023px;
$large-screen: 1199px;
$xlarge-screen: 1439px;


$small-breakpoint:  em-calc(640);
$medium-breakpoint: em-calc(1024);
$large-breakpoint:  em-calc(1200);
$xlarge-breakpoint:  em-calc(1440);

$small-breakpoint-down:  em-calc(639);
$medium-breakpoint-down: em-calc(1023);
$large-breakpoint-down:  em-calc(1199);
$xlarge-breakpoint-down:  em-calc(1439);


$small-range:   (0, $small-breakpoint);
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint);
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint);
$xlarge-range:  ($large-breakpoint + em-calc(1), $xlarge-breakpoint);


$small-up: $screen;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$small-and-up: "#{$screen} and (min-width : 0)";
$medium-and-up: "#{$screen} and (min-width : #{$small-screen  + 1})";
$large-and-up: "#{$screen} and (min-width : #{$medium-screen + 1} )";
$xlarge-and-up: "#{$screen} and (min-width: #{$large-screen + 1} )";

$small-and-down: "#{$screen} and (max-width : #{$small-screen})";
$medium-and-down: "#{$screen} and (max-width : #{$medium-screen})";
$large-and-down: "#{$screen} and (max-width : #{$large-screen})";
$xlarge-and-down: "#{$screen} and (max-width : #{$xlarge-screen})";

//$medium-only: "#{$screen} and (min-width : #{$small-screen}) and (max-width : #{$medium-screen})";

// Grid Variables
$num-cols: 12;
$gutter-width: 1rem;