@import url(https://fonts.googleapis.com/css?family=Lobster&subset=latin,latin-ext);

@import "design/variables";
@import 'design/template/mixin_templates';

body {
    font-size: 11px;
    height: auto;
    padding-bottom: 20px;
    overflow-x: hidden;
    width: 100%;
    min-width: 0 !important;
}

@include titreBaseSize();

.cmonsite-ficheproduit #main-conteneur .wrapper-content #bloc-fil-title {
    margin-bottom: 10px;
    > h2, > .h2, > h1, > .h1 {
        display: none;
    }
}

img {
    max-width: 100%;
    height: auto !important;
}

a{
    text-decoration:none;
}
a:hover {
    text-decoration: underline;
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
    font-family: Lobster, cursive;
    font-weight: 400;
}
.h2, h2 {
    border-bottom: 1px solid;
}

p {
    overflow: hidden;
}

ul {
    margin: 0;
    padding: 0;
}

iframe{
    max-width: 100%;
}

input,
select,
textarea {
    box-shadow: inset 0 2px 2px 0 #c3c3c3;
    background-color: #fff;
    border: 1px solid #a3a3a3;
    border-bottom-color: #ddd;
    color: #333;
    padding: 4px 3px;
}

input[type=radio] {
    box-shadow: none;
}

input:hover,
select:hover,
textarea:hover {
    border: 1px solid #999;
}

input:active,
select:active,
textarea:active,
input:focus,
select:focus,
textarea:focus {
    box-shadow: 0 0 8px rgba(247, 20, 11, 0.72);
    border: 1px solid #f71447;
}

* .container_12 {
    margin-bottom: 20px;
    box-shadow: 0 6px 14px #000;
}

.button,
#paiementchoix .button {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 1) 0 1px 0;
    text-shadow: rgba(255, 255, 255, 1) 0 1px 0;
    text-decoration: none;
    list-style: none;
    font-family: Lobster, cursive;
    font-size: 1.3em;
    padding: 5px 20px;
}

/************************************************/
/*********************CONTENT********************/
/************************************************/

#main-conteneur {
    width: 960px;
    margin: 0 auto;
    box-shadow: 0 6px 14px #000;
    display: table;
    font-size: 0;
    height: 100%;
    position: relative;
    text-align: right;
    &::after{
        content:'';
        display: block;
        clear:both;
    }
}
.wrapper-content {
    width: 960px;
    float: right;
    box-sizing: border-box;
    /*background: #666 url(../images/imgd465series/bg_sidebar.png) repeat-y;*/
    //z-index: 50;
    padding-top: 15px;
    position: relative;
    ul, ol{
        list-style-position: inside;
        padding-left:10px;
    }
}
.wrapper {
    padding: 7px 15px;
    box-sizing: border-box;
    &:first-child {
        padding-top: 100px;
    }
}

#main-conteneur .wrapper-content,
footer {
    font-size: 1rem;
    display: table-cell;
    width: 730px;
    float: right;
    text-align: left;
}

/************************************************/
/*********************HEADER*********************/
/************************************************/

header {
    width: 230px;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 13px;
    vertical-align: top;
    display: table-cell;
    position: relative;
    left: 0;
    float: none;
}
.header-conteneur {
    width: 100%;
    height: 100%;
}
.close-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        top: -20%;
        right: 0;
        height: 200%;
        width: 300%;
        box-shadow: -25px 0 70px rgba(0, 0, 0, 0.6) inset;
    }
}
#blocContent {
    height: 200px;
    position: relative;
}
#headercontent {
    text-align: center;
    width: 934px;
    position: absolute;
    height: 100%;
}
.header_live_edit {
    height: 200px;
    position: relative;
}
.slogan_live_edit {
    position: relative;
    width: 100%;
    min-height: 60px;
}
.container-slogan {
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    position: relative;
    z-index: 9999;
}
.slogan {
    font-size: 2.2em;
    text-align: right;
    min-height: 20px;
    margin: 0 0 0 230px;
    max-width: 704px;
    position: absolute;
    right: 0;
    top: 50% !important;
    transform: translateY(-50%);
    bottom: auto !important;
    p{
        margin: 5px 0;
    }
}
.logo {
    left: 0;
    position: absolute;
    top: 30px;
    margin: 0 715px 0 0;
    max-width: 100%;
    display: inline-block;
    z-index: 2;
    img {
        max-width: 100%;
        max-height: 150px;
        display: block;
    }
}

@media (min-width: $medium-screen) {
    header{
        &::before {
            content: '';
            width: 9px;
            height: 100%;
            position: absolute;
            background: rgba(255, 255, 255, 0.2);
            top: 0;
            left: 0;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 730px;
            height: 100px;
            left: 230px;
            top: 0;
            background: transparent;
        }
    }
    .header-conteneur{
        border-left: 1px solid rgba(0, 0, 0, 0.5) !important;
    }
}

/************************************************/
/***************DIAPORAMA ACCUEIL****************/
/************************************************/

.accueil_diaporama_template {
    .bx-controls {
        .bx-prev {
            left: 15px;
            z-index: 0;
        }
        .bx-next {
            right: 15px;
            z-index: 0;
        }
    }

    &.accueil_diaporama_template_1 {
        .bx-wrapper {
            margin-bottom: 35px;
        }
        .slide, .slide-text {
            max-height: 450px;
        }
        .addcommentaires {
            bottom: 10px;
            right: 10px;
            top: auto;
            left: auto;
            background: rgba(0, 0, 0, 0.7);;
            color: white;
        }
        .content-slide-text {
            a.addcommentaires {
                background-color: transparent;
            }
            a.slider-see-more {
                position: absolute;
                padding: 10px;
                float: right;
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.7);
                color: white;
                text-shadow: none;
                top: 10px;
                right: 10px;
            }
            p {
                width: 100%;
                left: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.6);
                border-top: 1px solid black;
                color: white;
                box-sizing: border-box;
                margin: 0;
                padding-right: 80px;
            }
        }
        .bx-controls .bx-pager.bx-default-pager {
            text-align: right;
            padding-right: 10px;
            box-sizing: border-box;
        }
    }

    &.accueil_diaporama_template_2 {
        padding-top: 100px !important;
        .content-slide-text {
            p {
                background: rgba(0, 0, 0, 0.7);
                color: white;
                bottom: 70px;
            }
            a.slider-see-more, a.addcommentaires {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.7);
                text-shadow: none;
                color: white;
            }
            a.addcommentaires {
                top: 20px;
                right: 20px;
            }
            a.slider-see-more {
                bottom: 20px;
                right: 20px;
            }
        }
    }

    &.accueil_diaporama_template_3 {
        .slide li {
            margin-left: 0;
            font-size: 0;

            &::before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
        }
        .center-img-slider {
            display: inline-block;
            vertical-align: middle;
        }
        .slide-text {
            font-size: 1rem;
        }
        .content-slide-text {
            p {
                background: rgba(0, 0, 0, 0.7);
                color: white;
                bottom: 70px;
            }
            a.slider-see-more, a.addcommentaires {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.7);
                text-shadow: none;
                color: white;
            }
            a.addcommentaires {
                top: 20px;
                right: 20px;
            }
            a.slider-see-more {
                bottom: 20px;
                right: 20px;
                top: auto;
                padding: 10px;
            }
        }
    }

    &.accueil_diaporama_template_4{
        margin-top: 0;
        padding-top:100px;
        .slide li {
            max-height: 400px;
            max-width: 730px;
            .center-img-slider img {
                max-height: 400px;
                min-height: 400px;
            }
        }
        .content-slide-text {
            p {
                background: rgba(0, 0, 0, 0.7);
                color: white;
                bottom: 70px;
            }
            a.slider-see-more, a.addcommentaires {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.7);
                text-shadow: none;
                color: white;
            }
            a.addcommentaires {
                top: 20px;
                right: 20px;
            }
            a.slider-see-more {
                bottom: 20px;
                right: 20px;
            }
        }
    }

    &.accueil_diaporama_template_5{
        .content-slide-text{
            a.addcommentaires{
                top:0;
                right:0;
            }
            a.slider-see-more{
                top:0;
                left:0;
                right:auto;
            }
            p{
                bottom:0;
                left:0;
                right:0;
                text-align: center;
                width: 100%;
                box-sizing: border-box;
                margin-bottom:0;
            }
        }
    }

    &.accueil_diaporama_template_6{
        .content-slide-text {
            p {
                background: rgba(0, 0, 0, 0.7);
                color: white;
                bottom: 70px;
            }
            a.slider-see-more, a.addcommentaires {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.7);
                text-shadow: none;
                color: white;
            }
            a.addcommentaires {
                top: 20px;
                right: 20px;
            }
            a.slider-see-more {
                bottom: 20px;
                right: 20px;
            }
        }
    }
}

/************************************************/
/**********************MENU**********************/
/************************************************/

//Navigation principale

.menu.navigation_p {
    position: relative;
    z-index: 2;
}

nav#nav-principal {
    text-align: center;
    height: 100%;
    > ul {
        padding: 0;
        width: 100%;
        max-width: 100%;
        font-family: Lobster, cursive;
        font-size: 1.6em;
        > li {
            background: url(../images/imgd465series/bg_menu_link.png) no-repeat scroll center bottom transparent;
            padding: 10px 0;
            > a {
                display: block;
                width: 100%;
                text-align: center;
            }
            &:hover {
                background: url(../images/imgd465series/bg_menu_link_hover.png) no-repeat bottom center;
            }
        }
    }
    li {
        li.hasDropdown {
            margin: 10px 0;
        }
        list-style: none;
        margin: 0;
        ul {
            display: none;
            margin: 0 0 10px;
            a {
                font-size: .8em;
                display: block;
            }
            ul {
                display: block;
            }
        }
    }
}

@media screen and (min-width: $medium-screen) {
    .template_boutique_accueil{
        &.accueil_boutique_template_1{
            $largeurFleches: 70px;
            $margin: 20px;
            $nbrProduit: 3;
            @include produitAccueilTaille($largeurFleches, $nbrProduit, $margin);
        }
    }
    nav#nav-principal .p-contact {
        display: block;
        width: 50%;
        padding: 50% 0 0;
        border-radius: 50%;
        position: relative;
        left: 25%;
        margin-top: 15px;
        box-shadow: 0 0 3px #000 inset;
        background: rgba(255, 255, 255, .5) !important;
        &:hover {
            background: rgba(255, 255, 255, .5) !important;
        }
        color: #C0596C;
        > a {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            height: 100%;
            width: 100%;
            display: block;
            font-size: 0;
            color: #fff;
            &::before {
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle;
                content: '';
            }
            span {
                display: inline-block;
                font-size: 22px;
                vertical-align: middle;
                i {
                    display: block;
                }
            }
        }
    }
}

//Fil d'ariane

.nav-static-breadcrumb{
    ul{
        &::after{
            content:'';
            display: block;
            clear: both;
        }
    }
    li {
        text-align: center;
        border-radius: 5px;
        box-shadow:  0 1px 0 rgba(0, 0, 0, 1);
        margin:10px 0 10px 15px;
        font-size: 0;
        &:first-child {
            margin-left: 0;
        }
        &::after{
            display: none !important;
        }

        &:hover {
            background: #ccc;
        }
    }
    a {
        border-radius: 5px;
        padding: 5px 15px;
        display: inline-block;
        box-sizing: border-box;
        font-size: 1rem;
        font-family: 'lobster', sans-serif;
    }

    ~ .title_section{
        display: none;
    }
}

/************************************************/
/********************PRODUITS********************/
/************************************************/

//Accueil

.template_boutique_accueil{
    &.accueil_boutique_template_1 .produits{
        padding:5px;
        .remise{
            top:100px;
        }
        .prix{
            text-align: center;
        }
    }

    &.accueil_boutique_template_2 .produits{
        .zoom {
            position: absolute;
            background: rgba(0,0,0,0.5);
            width: 100%;
            height: 100%;
            left: auto;
            right: auto;
            box-shadow: none;
        }
        .attr select {
            width: 100%;
        }
        .addbasket, .hors-stock {
            font-size: 18px;
        }
    }
}

//Tous les produits

.addbasket{
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 1) 0 1px 0;
    text-shadow: rgba(255, 255, 255, 1) 0 1px 0;
    text-decoration: none;
    list-style: none;
    font-family: Lobster, cursive;
    padding: 5px 20px;
    text-align: center;
    float: left;
}

.produit_etiquette{
    height:auto;
}

.template_boutique_all .produits{
    padding:5px;
}

.produits{
    position: relative;
    padding:5px;
    *, *::before, *::after{
        box-sizing: border-box;
    }
    .container-etiquette {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
    .produit_etiquette{
        z-index: 100;
        width: 100%;
        h3, .h3 {
            padding: 5px 0;
            line-height: 20px;
        }
    }
    .produit_etiquette2 {
        bottom: 5px;
        right: 15px;
        &::after {
            border-width: 0 15px 10px 0;
            content: "";
        }
    }
    .mask {
        position: absolute;
        width:100%;
    }
    .zoom{
        border-radius: 0;
    }
    .zoom::before{
        display: none;
    }
    p {
        font-size: .9em;
        margin: 5px 0;
    }
    .remise {
        position: absolute;
        display: block;
        font-size: 1.2em;
        font-weight: 700;
        box-shadow: 4px 4px 0 rgba(50, 50, 50, 0.6);
        margin: 0;
        padding: 2px 7px;
        z-index: 999;
        top: 130px;
        left: 5px;
        span {
            font-size: .9em;
        }
    }
    .nomprod {
        font-weight: 700;
        display: block;
        font-size: .9em;
        line-height: 1em;
        margin:15px 0;
    }
    .prix {
        .oldprix {
            text-decoration: line-through;
            color: #999;
            font-weight: 700;
            display: inline-block;
            font-size: 1.1em;
            margin: 0 0 0 5px;
        }
        .prixprod {
            margin: 0;
            padding: 0;
            display: inline-block;
            font-weight: 700;
            font-size: 1.1em;

        }
        span {
            float: none;
        }

    }
    .productImageWrap {
        margin: 0 auto 0;
        line-height: 0;
        > a {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
    }
    .productContentWrap {
        display: flex;
        flex-direction: column;
    }
    .desc {
        display: flex;
        order: 1;
        font-size: 0.8em;
    }
    .attr {
        box-sizing: border-box;
        order: 2;
        float: none;
        position: relative;
        padding: 0;
        margin: 10px auto;
        height: 29px;
        width: 100%;
    }
    select {
        height: 29px;
        box-shadow: none;
        border: none;
        margin: 0 auto;
        display: block;
    }
    a.addbasket {
        cursor: pointer;
        display: block;
    }
    .block-btn-addbasket{
        order: 3;
    }
    a.addbasket, .hors-stock {
        text-decoration: none;
        min-height: 0;
        font-size: 13px;
        max-width: 85%;
        width: auto;
        margin: 10px auto;
        float: none;
        padding: 10px;
        box-sizing: border-box;
    }
    .hors-stock {
        max-width: 100%;
        padding:5px 0;
    }
    &:hover{
        .zoom{
            opacity:.5;
        }
    }

    .note-produit {
        text-align: center;
    }
}

.template_boutique{
    &.template_boutique_1 .produits{
        .addbasket, .hors-stock{
            width: 100%;
            font-size: 18px;
        }
        .addbasket.next-select, .hors-stock.next-select{
            float: none;
            margin:10px auto;
        }
        .prix .prixprod, .prix .oldprix{
            float:none;
            display: inline-block;
        }
        .attr{
            float:none;
            position: relative;
            width: 100%;
        }
    }
    &.template_boutique_2 .produits{
        .remise {
            top: 270px;
        }
        .desc {
            display: none;
        }
        .prix .prixprod{
            margin: 0;
        }
        .addbasket, .hors-stock {
            font-size: 18px;
            float: none;
            width: auto;
            margin:10px auto;
        }
    }
    &.template_boutique_3 .produits{
        .container-etiquette{
            width:calc(30% - 3px);
        }
        .attr {
            text-align: center;
            &:empty {
                display: none;
            }
            select {
                float: none;
            }
        }
        .addbasket {
            position: relative;
            width: 50%;
            font-size: 18px;
            text-decoration: none;
        }
        .nomprod{
            margin: 15px 0;
            text-align: center;
        }
        &.is_etiquette .nomprod {
            text-align: center;
        }
        .prix{
            text-align: center;
        }
        .prix{
            .oldprix, .prixprod{
                float: none;
                margin:0 5px;
                display: inline-block;
            }
        }
    }
    &.template_boutique_4 .produits {
        .remise {
            top: 115px;
        }
        .container-etiquette {
            top: 0;
            h3, .h3 {
                font-size: 15px;
            }
        }
        .desc {
            display: none;
        }
        .attr {
            text-align: center;
            select {
                width: 100%;
                right: 0;
                left: 0;
            }
        }
        .addbasket {
            max-width: 100%;
            padding: 5px;
            text-decoration: none;
        }
    }
    &.template_boutique_5 .produits {
        padding: 0;
        .remise {
            top: 90px;
        }
        .container-etiquette {
            padding-left: 0;
            margin: 0;
            .produit_etiquette{
                padding:5px 0;
            }
        }
        .productContentWrap {
            //width: 50%;
            display: block;
            padding:0 5px;
        }
        .productImageWrap{
            margin-bottom: 10px;
        }
        .nomprod {
            //width: auto;
        }
        .prix {
            //width: auto;
            text-align: center;
            .oldprix{
                margin-left:0;
            }
        }
        .desc {
            display: none;
        }
        .addbasket, .hors-stock {
            box-sizing: border-box;
            font-size: 16px;
            text-decoration: none;
        }
        .attr{
            display: block;
        }
    }
}

/*.template_boutique_accueil{
    &.accueil_boutique_template_1,
    &.accueil_boutique_template_2{
        .container-etiquette{
            margin: 0;
        }
    }
}*/

/************************************************/
/*****************FICHE PRODUITS*****************/
/************************************************/

#fiche-produit{
    .zoomPad{
        z-index: 5;
    }
    .produit_etiquette {
        z-index: 100;
    }
    .wrap-images {
        .grande-image .remise-produit {
            display: block !important;
            position: absolute;
            font-size: 1.2em;
            font-weight: 700;
            box-shadow: 4px 4px 0 rgba(50, 50, 50, 0.6);
            margin: 0;
            padding: 2px 7px;
            z-index: 999;
            top: 45px;
            left: auto;
            width: auto;
            height: auto;
            border-radius: 0;
        }
        p{
            overflow: visible;
        }
    }

    .wrap-description {
        > .row {

            h3, .h3, h1, .h1 {
                margin-bottom: 0;
                margin-top:0;
            }
        }
        .brand{
            border:none !important;
        }
        .pro-pannier {
            display: flex;
            flex-direction: column;
            .declinaison-attribute-group,.product_options-group {

                .radio_unique {
                    border: 1px solid #8b8b8b;
                    margin: 10px 5px 10px 0;
                    border-radius: 0;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .declinaison {
                order: 3;
                margin: 0;
            }
            .bloc-quantite {
                input {
                    text-align: center;
                }
            }
            .ajouter-panier {
                a.addbasket {
                    text-decoration: none;
                    display: inline-block;
                }
            }
        }
        .triangle-ficheprod {
            display: none;
        }
    }
}

.template_fiche_produit{
    &.template_fiche_produit_1 #fiche-produit {
        .wrap-details #addcomment {
            margin: 10px auto;
        }
    }
    &.template_fiche_produit_2 #fiche-produit{
        .wrap-images {
            box-sizing: border-box;
            padding: 0;
        }
        .wrap-description {
            .container-etiquette {
                left: 0;
            }
            h1, .h1 {
                margin: 0;
                font-size: 24px;
            }
            .declinaison-attribute-group,.product_options-group {
                padding: 0;
                .radio_unique {
                    border: 1px solid #8b8b8b;
                    margin: 10px 5px 10px 0;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .bloc-quantite {
                margin: 10px 0;
            }
            .ajouter-panier {
                .addbasket {
                    margin: 10px auto;
                    font-size: 15px;
                    text-decoration: none;
                    .fa {
                        margin-right: 10px;
                    }
                }
            }
        }
        .wrap-details.bloc-avis-clients {
            h4, .h4 {
                text-align: center;
            }
            #addcomment {
                width: 100%;
                #formulaire .form-group {
                    padding: 0;
                }
            }
        }
        #savoirplus {
            padding: 0;
        }
        .top_fiche .triangle-ficheprod{
            top:0;
            right:0;
        }
    }
    &.template_fiche_produit_3 #fiche-produit{
        .wrap-images {
            margin: 20px auto;
            ul.sliderImageFicheProd > li {
                margin-left: 0;
            }
        }
        .wrap-description {
            h1, .h1 {
                margin-top: 0;
            }
            .side-tabs {
                width: 100%;
                > a {
                    width: 25%;
                    float: left;
                    margin: 0 3%;
                }
            }
            .in-onglet {
                width: 100%;
                margin-top: 20px;
            }
            .declinaisons-achat .container-etiquette {
                display: none;
            }

            .bloc-quantite {
                order: 2;
                margin: 10px 0 0 0;
                width: 100%;
                .quantite{
                    margin-bottom: 0;
                }

            }

            .ajouter-panier {
                order: 3;
                padding-top: 0;
            }

            .declinaison {
                order: 4;
            }

            .prix {
                order: 1;
                width: 100%;
                text-align: center;
                .oldprix {
                    display: inline-block;
                }
                .prixprod {
                    display: inline-block;
                    width: auto;
                }
            }
            .pro-pannier {
                .declinaison-attribute-group,.product_options-group {
                    padding: 0;
                    .radio_unique {

                        border: 1px solid #8b8b8b;
                        margin: 10px 5px 10px 0;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .ajouter-panier {
                    .addbasket {
                        margin: 10px auto;
                        font-size: 15px;
                        text-decoration: none;
                        .fa {

                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .wrap-details.bloc-avis-clients {
            h4, .h4 {
                text-align: center;
            }
            #addcomment #formulaire .form-group {
                padding: 0;
            }
        }
    }
}



#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        .fa {
            margin-right: 10px;
        }
    }

    > .button + .button {
        margin-left: 15px;
    }

}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}


/************************************************/
/********************GALERIES********************/
/************************************************/

.galerie .diaporama {
    text-align: center;
}

.template_album{
    &.template_album_1 .galerie{
        p {
            padding: 5px 10px;
        }
    }
}

/************************************************/
/*********************CONTACT********************/
/************************************************/

#captcha {
    display: block;
}

.template_contact{
    input[type="file"] {
        border: none;
        box-shadow: none;
    }
    &.template_contact_1, &.template_contact_3{
        .col.s12 form .col.s12 {
            padding: 0;
        }
    }
    &.template_contact_2 {
        .form {
            .form-group input {
                box-shadow: none;
            }
            input.button[type="submit"] {
                margin: 20px 0;
            }
        }
    }
    &.template_contact_3 {
        .form input.button[type="submit"] {
            float: left;
            margin: 20px 0;
        }
    }
    &.template_contact_4 {
        div.row.blocTxtAddr {
            margin: 0;
        }
        .row.blocF .tmp4blocC form .form-group input, .row.blocF .tmp4blocC form .form-group textarea {
            float: right;
            width: 70%;
        }
        .form input.button[type="submit"] {
            margin: 20px 0;
        }
        &.content-cmonsite:not(.form-search) {
            overflow: inherit;
        }
    }
    &.template_contact_5 {

        .inmap {
            top: 10px;
            left: 20px;
        }

        textarea[name=message] {
            width: 100%;
        }

        .form input.button[type="submit"] {
            float: left;
            margin: 20px 0;
        }
    }
}

/************************************************/
/********************LIVRE d'OR******************/
/************************************************/

.template_livreor_2 .list-messages .message_lo .IDLO h4,
.template_livreor_2 .list-messages .message_lo .IDLO .h4 {
    display: block;
    margin-top: 5px;
}

.addmsglo {
    display: block;
    text-align: center;
    width: 100%;
    max-width: 280px;
    margin: 10px auto;
    clear: both;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 1) 0 1px 0;
    text-shadow: rgba(255, 255, 255, 1) 0 1px 0;
    text-decoration: none;
    list-style: none;
    font-family: Lobster, cursive;
    font-size: 1.3em;
    padding: 5px 20px;
}
.message_lo {
    margin:20px 0;
    border-bottom: 1px #666 solid;
    padding-top: 10px;
    float: left;
    width: 100%;
    h4, .h4{
        width: 80%;
    }
    .message {
        margin-left: 0;
        margin-right: 0;
    }

    .note {
        text-align: right;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
        float: right;
    }
}

/************************************************/
/**********************PANIER********************/
/************************************************/

.template-panier.template-panier-2 {
    width: 100%;
    margin: 15px 0;
    border: 0;
    background: rgba(255, 255, 255, 0.5);
    #header-panier {
        color: #fff;
        text-shadow: 0 1px 0 #000;
    }
    #paniercontent {
        left: calc(100% - 1px);
        top: -1px;
    }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    .side-tabs.etapes {
        padding-bottom: 15px;
    }

    .logo-carrier-none {
        width: auto;
    }

    h4 {
        margin: 0;
    }

    div#resume .block-resume-price .col.s6,
    div#finish .block-resume-price .col.s6 {
        width: 100%;
    }

    div#resume .block-price .block-with-background,
    div#finish .block-price .block-with-background {
        border-left: none;
    }

    #information #infoscommande .pull-right {
        /*Hack pour passer au dessus du important style all design, pour assurer un rendu propre*/
        float: none !important;
    }

    #connexion-inscription > .col.s6 {
        padding: 0;
        width: 100%;
        margin: 15px 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

body .template_flux_panier_2{
    .block-resume, .block-information, .recapitulatif.recap-l, .recapitulatif.recap-r{
        width: 100%;
    }
    .form-order::before, .block-finish::before{
        display: none;
    }
    #resume, .recapitulatif.recap-l{
        background: rgba(0,0,0,0.02);
    }
}

@media screen and (min-width: $medium-screen) {
    .cmonsite-panier-3 #main-conteneur {
        .menu.navigation_p {
            margin-top: 42px;
        }
        header{
            z-index: auto;
        }
    }
    .template-panier.template-panier-3 {
        top: 200px;
        left: 0;
        right: auto;
        width: 220px;
        border: none;
        background: rgba(255, 255, 255, 0.5);
        color: #fff;
        .bloc-connexion-panier .connexion p, .bloc-connexion-panier .connexion a, #header-panier {
            color: #fff;
            text-shadow: 0 1px 0 #000;
        }
        .connexion {
            p, a {
                color: #fff;
            }
            a {
                text-shadow: 0 1px 0 #000;
                @media screen and (min-width: $medium-screen) {
                    &::before {
                        display: block;
                        font-size: 1rem;
                        padding: 9px;
                        text-align: center;
                        margin: 0;
                    }
                }
            }
        }
        #header-panier {
            color: #fff;
            text-shadow: 0 1px 0 #000;
        }
        #paniercontent {
            left: 100%;
            top: 0;
        }
    }
}

/************************************************/
/*********************FOOTER*********************/
/************************************************/

footer {
    width: 100%;
    float: right;
    box-sizing: border-box;
    text-align: center;
    padding:15px;
    p{
        margin-bottom: 0;
    }
    .mention {
        padding: 15px;
        box-sizing: border-box;
        text-align: center;
    }
}

/********************* A GARDER POUR LES MODELS ********************/

/*Gerer les images dans les tableaux*/

.links_p {
    position: relative;
}
.zoomPupImgWrapper > img, .zoomWrapperImage > img {
    max-width: none;
}
table {
    position: relative;
    table-layout: fixed;
    max-width: 100%;
}
.produits, .galerie {
    box-sizing: border-box;
}
.preview {
    max-width: 95%;
}

/* Commandes */

#order .commandes td{
    padding:10px 0;
    border-top:1px solid rgba(128,128,128,.1);
}
#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: $medium-screen) {
    body {
        margin: 0;
    }
    #main-conteneur {
        width: $small-screen;
        display: block;
        header {
            height: 150px;
        }
        .wrapper-content {
            margin-top: 0;
            z-index: 50;
        }
    }
    header {
        display: block;
        width: 100%;
        padding-left: 0;
        z-index: 100;
        .close-menu::before {
            display: none;
        }
        .header-conteneur #blocContent #headercontent .container-slogan {
            width: 100%;
            display: block;
            position: initial;
            text-align: center;

            h1, .h1 {
                text-align: center;
                margin: 0;
            }
        }
        #blocContent {
            height: auto;
            #headercontent {
                width: 100%;
                height: auto;
                position: relative;
            }
        }
    }

    footer{
        width: 100%;
    }

    .template_boutique.template_boutique_4 .produits {
        padding: 10px;
        .productImageWrap {
            width: 100%;
            margin: 0;
        }
    }
    .template_boutique .produits .productImageWrap .container-etiquette,
    .template_boutique_accueil .produits .productImageWrap .container-etiquette {
        top: 0;
    }

    .bx-next, .bx-prev {
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-position: center center;
    }

    #block-flottant-search, #minisite-sidebar {
        display: none;
    }

    .container-slogan, #main-conteneur header {
        height: auto;
    }
    .slogan {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 15px;
        text-align: center !important;
        font-size: 1.6rem !important;
        display: block;
        width: 100% !important;
        transform: translateY(0);
    }

    #logo {
        position: relative !important;
        width: auto !important;
        max-width: 180px !important;
        text-align: center !important;
        left: 0 !important;
        top: 0 !important;
        margin: 15px auto 0;
        display: block;
        img {
            width: auto !important;
            max-height: 180px;
            display:  inline-block;
        }
    }

    #main-conteneur{
        .wrapper-content{
            display: block;
            width: 100%;
            padding: 10px;
            .accueil_diaporama_template{
                &.template_boutique_2 .productImageWrap {
                    width: 100%;
                }
                &.accueil_diaporama_template_3, .bx-wrapper:first-child {
                    padding: 10px;
                }
                &.accueil_diaporama_template_4,
                &.accueil_diaporama_template_6 {
                    padding-top: 0;
                }
            }
        }
    }

    .template_boutique{
        &.template_boutique_2 .produits{
            .productImageWrap {
                width: 100%;
            }
        }
    }

    footer {
        margin-bottom: 30px;
        display: block;
        width: 100%;
    }

    ///////////////////////////////////////////////////

    .template_fiche_produit {
        &.template_fiche_produit_1 #fiche-produit {
            .top_fiche .wrap-images {
                .next, .previous {
                    font-size: 30px;
                }
                .next {
                    text-align: right;
                }
                .previous {
                    text-align: left;
                }
            }
        }
        &.template_fiche_produit_2 #fiche-produit .top_fiche {
            .wrap-images {
                margin-top: 0;
            }
            .wrap-description {
                padding-left: 0;
                .pro-pannier {
                    .bloc-quantite {
                    }
                    .ajouter-panier .addbasket {
                        margin: 10px 0 10px 120px;
                        float: left;
                        text-decoration: none;
                    }
                }
            }
            #savoirplus {
                box-sizing: border-box;
                padding: 0;
            }
        }
        &.template_fiche_produit_3 #fiche-produit .top_fiche {
            .wrap-description {
                padding-left: 0;
                .pro-pannier {
                    .ajouter-panier .addbasket {
                        margin: 10px 0;
                        float: right;
                        width: 200px;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .template_boutique {
        &.template_boutique_4 .produits {
            .productImageWrap {
                height: auto;
                a {
                    max-height: none;
                    img {
                        max-height: none;
                    }
                }
            }
            .productContentWrap {
                a.addbasket, .hors-stock {
                    font-size: 18px;
                    box-sizing: border-box;
                    text-decoration: none;
                }
            }
        }
    }

    /* Menu responsive */

    #main-conteneur .template-panier.template-panier-2 {
        position: absolute;
        float: none;
        top: 10px;
        right: 70px;
        height: 50px;
    }
    #main-conteneur header .header-conteneur {
        position: relative;
        #headercontent {
            height: auto;
        }
        .menu {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
        }
        .template-panier-2 {
            margin: 0;
            top: 60px;
        }
    }

    nav#nav-principal {
        width: 100%;
        padding: 0;
        float: none;
        clear: both;
        position: static;
        a, a span {
            white-space: initial;
        }
        a {
            padding: 0 10px;
        }
        > a.menu_burger {
            position: absolute;
            top: 10px;
            right: 10px;
            margin-top: 0;
            display: block;
            padding: 0;
            text-align: center;
            width: 50px;
            height: 50px;
            line-height: 0;
            font-size: 59px;
            z-index: 50;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
            &::before{
                content:'\f0c9';
                font-family: 'FontAwesome', sans-serif;
                position: absolute;
                width: 28px;
                top: 50%;
                left: 11px;
                font-size: 30px;
                margin: 0;
            }
        }

        &.nav_ouverte > a.menu_burger {
            font-size: 0;
            &::after {
                margin-top: 0;
                transform: rotate(-45deg);
            }
        }

        > ul {
            height: auto;
            min-width: 0;
            max-width: 300px;
            display: none;
            top: 70px;
            position: absolute;
            width: 260px;
            right: 10px;
            li ul {
                left: 0;
                opacity: 1;
                top: 0;
                margin-top: 0;
                padding: 0;
                margin-left: 0;
                &::before {
                    left: 50%;
                    top: -10px;
                    margin-left: -6px;
                    border-width: 0 6px 8px 6px;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    border-top-color: transparent;
                }
                ul {
                    box-sizing: content-box;
                    left: -2px;
                }
            }
            li {
                width: 100%;
                display: block;
                margin: 0;
                padding: 0;
                position: relative;
            }
        }

        a {
            font-size: 16px;
            line-height: 55px;
            padding: 0;
            width: 100%;
            text-align: center;
            > span::after {
                display: none;
            }
        }
        &.nav_ouverte {
            > ul {
                display: block;
            }
        }
    }
    .menu_burger {
        text-align: center;
        &::before {
            content: '\f0c9';
            display: inline-block;
            margin-right: 5px;
            font-family: 'FontAwesome', sans-serif;
        }
    }
    .dropDown {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
        display: block;
        height: 55px;
        width: 60px;
        line-height: 55px;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        &::before {
            font-family: 'fontAwesome', sans-serif;
            content: "\f107";
        }
    }

    .sousmenu_ouvert > .dropDown::before {
        content: "\f106";
    }
}

@media screen and (min-width: $small-screen) {
    .menu_burger {
        display: none;
    }
}

@media screen and (max-width: $small-screen) {

    .template_boutique{
        &.template_boutique_3 .produits{
            .container-etiquette{
                width: auto;
            }
        }
    }

    .header_live_edit {
        height: auto;
    }
    .slogan {
        left: 0 !important;
        top: 0 !important;
        right: auto !important;
        bottom: auto !important;
        text-align: center !important;
        width: 100% !important;
        position: relative !important;
        height: auto !important;
        margin: 0;
    }
    .logo {
        padding-bottom: 15px;
        position: static !important;
        display: block !important;
        margin: 0 auto !important;
        padding-top: 15px;
        height: auto !important;
        text-align: center;
        img {
            max-height: 180px !important;
            width: auto !important;
            max-width: 100% !important;
            height: auto !important;
            position: static !important;

        }
    }

    .search-bar {
        width: 90%;
    }

    /*Fiche produit*/
    #fiche-produit{
        #formulaire .form-group {
            float: none;
        }
        .input-form-petit {
            width: auto;
        }
        .input-form-moyen {
            width: 100%;
        }
        .wrap-images, .wrap-description {
            width: 100%;
            float: none;
        }
    }

    /*Desactivation du hover sur images produits*/
    .zoomWindow, .zoomPup {
        display: none;
    }
    .zoomPad {
        cursor: initial;
    }
    .zoomPad {
        display: inline-block;
        width: 80%;
        margin: 0 10%;
        text-align: center;
        img {
            width: 100%;
            max-height: 100%;
            opacity: 1;
        }
    }

    form .row .col.s3 {
        width: 25%;
    }
    .form-group textarea {
        width: 100%;
    }
    .thumbnail {
        float: left;
        width: 42%;
        margin: 2.5%;
    }
    td {
        max-width: 100%;
    }

    /*Ajuste tous les tableaux des templates*/
    .template table, .template td, .template tbody, .template tr {
        width: 100%;
        display: inline-block;
        margin: 5px auto;
    }

    @import 'design/helper/responsive/mobile/mobile';

    .ui-datepicker {
        width: 98%;
        font-size: .75em;
        left: 0;
    }
    .search-bar {
        width: 85%;
    }
    #panier {
        right: 0;
        width: 100%;
        box-sizing: border-box;
    }
    .ui-datepicker {
        left: 0;
    }
    .thumbnail {
        float: none;
        width: 75%;
        margin: 0 auto 12px;
        padding: 15px;
    }
    .content-payment-global {
        font-size: 15px;
    }
    a.addbasket.button {
        display: inline-block;
        padding-left: 15px;
        width: auto;
        max-width: none;
        text-decoration: none;
    }

    #main-conteneur {
        width: 100%;
        .produits-accueil.template_boutique_accueil {
            .produits {
                text-align: center;
                .prix .prixprod, .prix .oldprix {
                    float: none;
                }
            }
        }
        #fiche-produit {
            .wrap-images {
                margin: 15px 0;
            }
            .wrap-description {
                .pro-pannier .ajouter-panier a.addbasket {
                    font-size: 15px;
                    text-decoration: none;
                }
            }
            .wrap-details {
                .details p {
                    font-size: 16px;
                }
            }
        }
        .wrapper-content .wrapper {
            padding: 7px 10px;
        }
        h2, .h2 {
            margin: 10px 0;
        }
        .wrapper-content .template_boutique .produits .productImageWrap, .wrapper-content .template_boutique_accueil .produits .productImageWrap {
            height: auto;
            > a {
                max-height: none;
                > img {
                    max-height: none;
                }
            }
        }
    }
}

@media #{$medium-and-down} {
    .cmonsite-panier-3 {
        #blocContent {
            #headercontent {
                padding-bottom: 20px;
            }
            .template-panier.template-panier-3 {
                width: 100%;
                max-width: 300px;
                top: auto;
                right: auto;
                left: auto;
                border: 1px solid;
                margin: 10px auto 0;
                position: static;
                float: none;
                height: auto;
                clear: both;
                &.sans-connexion {
                    width: 100%;
                }
                .connexion {
                    width: 100%;
                    padding: 0;
                    p {
                        border: none;
                    }
                }
                #header-panier {
                    text-align: center;
                    position: relative;
                }
                .count-live {
                    left: auto;
                    top: auto;
                    margin-left: 10px;
                }
                .bloc-connexion-panier {
                    #panier {
                        position: static;
                        width: 100%;
                        #paniercontent {
                            min-width: 0;
                            left: 5%;
                            right: 5%;
                            width: 90%;
                            max-width: 100%;
                            box-sizing: border-box;
                            margin-top: -21px;
                            z-index: 9999;
                            top: 100%;
                        }
                        .paniertoggle {
                            float: none;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            .fa {
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                margin-top: -8px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity{
    height: auto;
}